'use strict';
export default class Loading {
	constructor( config ) {
		this.targetId = config.id;
		this.target = document.querySelector( this.targetId );

		// ローダー要素生成
		this.loader = document.createElement( 'div' );
		this.loader.classList.add( 'l-loader' );
		document.querySelector( 'body' ).insertBefore( this.loader, this.target );

		this.loaderIcon = document.createElement( 'div' );
		this.loaderIcon.classList.add( 'l-loader__icon' );
		this.loader.appendChild( this.loaderIcon );

		// イベントの種類を取得
		this.scrollEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';

		// スクロール禁止
		this.noScroll();
	}

	init() {
		document.documentElement.classList.add( 'is-loaded' );
		this.target.classList.add( 'is-loaded' );
		this.loader.classList.add( 'is-loaded' );

		this.loader.addEventListener( 'transitionend', () => {
			this.loader.parentNode.removeChild( this.loader );
			this.releaseScroll();
		});
	}

	// スクロール禁止
	noScroll() {
		//PC用
		document.addEventListener( this.scrollEvent, this.deleteDefaultEvent, true);

		//SP用
		document.addEventListener( 'touchmove.noScroll', this.deleteDefaultEvent, true);
	}

	// スクロール禁止の解除
	releaseScroll() {
		//PC用
		document.removeEventListener( this.scrollEvent, this.deleteDefaultEvent, true );

		//SP用
		document.removeEventListener( '.noScroll', this.deleteDefaultEvent, true );
	}

	deleteDefaultEvent( e ) {
		e.preventDefault();
	}

}
