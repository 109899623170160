'use strict';

// import NewsList from '../module/news_list.js';

export default class HeaderMenu {

	// 引数からデータの取得, Node 取得
	constructor( config ) {
		this.navi = config.navi;
		this.trigger = config.trigger;
		this.target = config.target;
		this.initWidth = config.initWidth;

		this.menu = [].slice.call( document.querySelectorAll( '.has-subMenu' ) );
		this.triggerEl = document.querySelector( this.trigger );
		this.targetEl = document.querySelector( this.target );

		this.statusSpMenu = 'close';
	}

	// イベントリスナーの登録
	init() {
		this.menu.forEach( el => {
			let content = el.children[0].nextElementSibling;	// アニメーション対象の要素
			let copyContent = content.cloneNode( true );		// 要素のコピーを作成
			copyContent.classList.add( 'is-clone' );			// コピーした要素にクラスを追加
			content.parentNode.appendChild( copyContent );		// コピーした要素を親 Node に挿入

			// コピーした要素を閲覧不可に
			if( window.matchMedia('(min-width:1025px)').matches ) {
				copyContent.style.cssText = 'display:block; height:auto; visibility:hidden;';
			} else {
				// copyContent.style.cssText = 'position:absolute;z-index:-1;display:block; height:auto; visibility:hidden;'
				// 日本語ページはサブメニューにを非表示にし、メインメニュータップでスライド
				// 英語ページの場合は、サブメニューを表示
				if( !content.classList.contains('en') ) {
					content.style.cssText = 'display:none;';
				}
				copyContent.style.cssText = 'display:none; height:auto; visibility:hidden;';
			}

			// 各メニューに hover のイベントリスナーを登録
			el.addEventListener( 'mouseenter', () => {
				if( window.matchMedia('(min-width:1025px)').matches ) {
					el.children[0].classList.add( 'is-active' );

					let contentH = content.offsetHeight;
					let copyContentH = copyContent.offsetHeight;

					if( window.matchMedia('(min-width:1025px)').matches ) {
						content.style.display = 'block';
						this.slideDown( content, contentH, copyContentH );
					}
				}
			});
			el.addEventListener( 'mouseleave', () => {
				if( window.matchMedia('(min-width:1025px)').matches ) {
					el.children[0].classList.remove( 'is-active' );
					content.style.height = '0';
					content.style.display = 'none';
				}
			});

		});

		// ハンバーガーボタンに click のイベントリスナーを登録
		this.triggerEl.addEventListener( 'click', () => {
			this.triggerEl.classList.toggle( 'is-open' );

			let body_h = document.querySelector( 'body' ).offsetHeight;
			let header_h = document.querySelector( '#l-header' ).offsetHeight;
			let timer;

			this.targetEl.style.height = body_h - header_h + 'px';
			this.targetEl.style.top = header_h + 'px';

			if( this.triggerEl.classList.contains( 'is-open' ) ){
				this.statusSpMenu = 'open';
				this.targetEl.style.display = 'block';
				timer = setInterval( () => {
					if( this.targetEl.style.opacity < 1 ){
						this.targetEl.style.opacity = Number( this.targetEl.style.opacity ) + 0.1;
					} else {
						clearTimeout( timer );
					}
				}, 10 );
			} else {
				this.statusSpMenu = 'close';
				timer = setInterval( () => {
					if( this.targetEl.style.opacity > 0 ){
						this.targetEl.style.opacity = Number( this.targetEl.style.opacity ) - 0.1;
					} else {
						this.targetEl.style.display = 'none';
						clearTimeout( timer );
					}
				}, 10 );
			}
		});

		// ウィンドウリサイズ時のイベントリスナーを登録
		window.addEventListener( 'resize', () => {
			let clone = document.querySelectorAll( this.navi + ' .is-clone' );

			if( window.matchMedia('(min-width:1025px)').matches ) {
				clone.forEach( el => {
					el.style.display = 'block';
				} );
				this.targetEl.style.height = '';
				this.targetEl.style.top = '';
				this.targetEl.style.display = 'block';
				this.targetEl.style.opacity = '1';
			} else {
				if( this.initWidth != window.innerWidth ) {
					clone.forEach( el => {
						el.style.display = 'none';
					} );

					this.menu.forEach( el => {
						let _this = el.children[0].nextElementSibling;
						if( !_this.classList.contains( 'is-clone' ) ) {
							// 英語ページはサブメニューを開いたままに
							if( !_this.classList.contains( 'en' ) ) {
								_this.style.display = 'none';
								_this.style.height = '';
							}
						}
					});

					let body_h = document.querySelector( 'body' ).offsetHeight;
					let header_h = document.querySelector( '#l-header' ).offsetHeight;
					this.targetEl.style.height = body_h - header_h + 'px';
					this.targetEl.style.top = header_h + 'px';

					if( this.statusSpMenu == 'open' ) {
						this.triggerEl.classList.add( 'is-open' );
						this.targetEl.style.display = 'block';
					} else {
						this.triggerEl.classList.remove( 'is-open' );
						this.targetEl.style.display = '';
					}
				}
			}
		});
	}

	// アコーディオン
	slideDown( content, h, copyH ) {
		let timer;

		if ( h < copyH ) {
			let _h = h + 5;
			content.style.height = _h + 'px';
			timer = setTimeout( () => {
				this.slideDown( content, _h, copyH );
			}, 5 );
		} else {
			clearTimeout( timer );
			content.style.height = copyH;
		}
	}
}
