'use strict';

export default class ChangeFontsize {
	constructor( config ) {
		this.button = [].slice.call( document.querySelectorAll( config.selector ) );
		this.rootEl = document.querySelector( 'html' );
	}

	init() {
		let fontsize = localStorage.getItem( 'fontsize' );

		if( fontsize === 'large' ) {
			this.rootEl.classList.add( 'is-fontsize--large' );
			localStorage.setItem( 'fontsize', 'large' );
		} else {
			this.rootEl.classList.remove( 'is-fontsize--large' );
			localStorage.setItem( 'fontsize', 'medium' );
		}

		this.button.forEach( el => {
			el.addEventListener( 'click', () => {
				let param = el.dataset.fontsize;
				this.setFontsize( param );
			});
		} );
	}

	setFontsize ( param ) {
		if( param === 'large' ) {
			this.rootEl.classList.add( 'is-fontsize--large' );
			localStorage.setItem( 'fontsize', 'large' );
		} else {
			this.rootEl.classList.remove( 'is-fontsize--large' );
			localStorage.setItem( 'fontsize', 'medium' );
		}
	}
}
