'use strict';

export default class Tab {
	constructor( config ) {
		this.selector = config.selector;
	}

	init() {
		this.button = [].slice.call( document.querySelectorAll( '.p-tab button' ) );
		this.button.forEach( ( el, index ) => {
			el.addEventListener( 'click', () => {
				this.clickTab( index );
			});
		});

		this.content = [].slice.call( document.querySelectorAll( '.p-tab__content' ) );
	}

	clickTab ( index ) {
		this.button.forEach( ( el, i ) => {
			if( i === index ) {
				el.classList.add( 'is-active' );
			} else {
				el.classList.remove( 'is-active' );
			}
		});

		this.content.forEach( ( el, i ) => {
			if( i === index ) {
				el.classList.add( 'is-active' );
			} else {
				el.classList.remove( 'is-active' );
			}
		});
	}
}
