import moment from 'moment';
import 'moment/locale/ja';
moment.locale('ja');

import Loading from './loading.js';
import HeaderMenu from './header_menu.js';
import FooterPagetop from './footer_pagetop.js';
import SelectYear from './select_year.js';
import ChangeFontsize from './change_fontsize.js';
import Tab from './tab.js';
import Presen  from './presen.js';

const LOADING_CONF = {
	id: '#l-wrapper'
};
const LOADING = new Loading( LOADING_CONF );

const FOOTER_PAGETOP_TRRIGER = document.querySelector( '#l-pagetopMoving' );
const FOOTER_PAGETOP = new FooterPagetop( FOOTER_PAGETOP_TRRIGER );

// load
window.addEventListener( 'load', () => {
	LOADING.init();
});

// DOMContentLoaded
document.addEventListener( 'DOMContentLoaded', () => {

	let initWidth = window.innerWidth;

	// ヘッダーの制御
	const HEADER_MENU_CONF = {
		navi: '#l-globalNavi',
		trigger: '#l-hamburger',
		target: '#l-menu',
		initWidth: initWidth
	};
	const HEADER_MENU = new HeaderMenu( HEADER_MENU_CONF );
	HEADER_MENU.init();

	// スムーススクロール
	$('[data-scroll], a[href^="#"]').on('click' ,function(e) {
		if( $(this).hasClass('noScroll') ){
			e.preventDefault();
		}else{
			let speed = 500;
			let attr = $(this).attr('href');
			let href;
			let target;

			if( typeof attr !== 'undefined' && attr !== false ) {
				href = $(this).attr('href');
			}else{
				href = '#' + $(this).data('scroll');
			}

			if( href == '#' || href == '' ) {
				target = $('html');
			}else{
				target = $(href);
			}

			let position = target.offset().top;
			$('body,html').animate({ scrollTop: position }, speed, 'swing');
			e.preventDefault();
		}
	});

	// アコーディオン
	$('.js-accordion, .p-indexD__title').on( 'click', function(){
		$(this).toggleClass( 'is-open' );
		$(this).next().slideToggle();
	} );

	// タブ
	const TAB_CONF = {
		selector: '.js-tab',
	};
	const TAB = new Tab( TAB_CONF );
	TAB.init();

	// セレクトボックス
	const SELECT_YEAR_CONF = {
		selector: '.js-select',
	};
	const SELECT_YEAR = new SelectYear( SELECT_YEAR_CONF );
	SELECT_YEAR.init();

	// フォントサイズの変更
	const CHANGE_FONTSIZE_CONF = {
		selector: '.js-fontsize',
	};
	const CHANGE_FONTSIZE = new ChangeFontsize( CHANGE_FONTSIZE_CONF );
	CHANGE_FONTSIZE.init();

	// プリント機能
	let button = document.querySelector( '#js-print' );
	if( button ){
		button.addEventListener( 'click', e => {
			e.preventDefault();
			window.print();
		} );
	}

	// 説明会資料
	if( document.querySelector( '#js-presen' ) ){
		const SELECTOR = {
			index: '[data-index]',
			pages: '[data-num]',
			next: '#js-presen--next',
			prev: '#js-presen--prev'
		};
		const PRESEN = new Presen( SELECTOR );
		PRESEN.init();
	}

	// スマホのメニュー折り畳み
	$('.l-globalNavi__mainText').on('click', function(e) {
		if( window.matchMedia('(max-width:1024px)').matches ) {
			e.preventDefault();
			$(this).toggleClass('is-open');
			$(this).next().slideToggle();
		}
	});

	// PCメニューの縮小
	$(window).on('scroll', function() {
		let winScrollTop = $(this).scrollTop();
		if( window.matchMedia('(min-width:1025px)').matches ) {
			if(winScrollTop > 200) {
				$('#l-globalNavi').addClass('is-fixed');
			} else {
				$('#l-globalNavi').removeClass('is-fixed');
			}
		}
	});

});

// scroll
window.addEventListener( 'scroll', () => {
	FOOTER_PAGETOP.setStatus();
});

// resize
window.addEventListener( 'resize', () => {
	FOOTER_PAGETOP.setStatus();
});

// forigin 取得
let origin = window.location.origin;
if( origin === 'http://localhost:6300' || origin === 'http://192.168.100.103:6300' ) {
	origin = 'https://t2.imagicagroup.iw0.jp';
}

/**
 * 年度リスト取得
 * - /news/：最新年度一覧
 * - /news/YYYY/：年度一覧
 * - /news/YYYY/yyyymmddnn：詳細記事
 */
let request_get_years = $.ajax({
	type: 'GET',
	url: origin + '/news/nendo/1.0/732hUdjYZcF5Cq/get_years',
	dataType: 'jsonp',
	jsonpCallback: 'get_years',
});
request_get_years.done(function(json) {
	localStorage.setItem( 'years', JSON.stringify(json) );

	// ヘッダー, フッター
	if( $('.js-newsYears').length ){
		let dom = '';
		for( let i = 0; i < json.length; i++ ) {
			if( i === 0 ) {
				dom += `<li><a href="/news/">${json[i]}年度</a></li>`;
			} else {
				dom += `<li><a href="/news/${json[i]}/">${json[i]}年度</a></li>`;
			}
		}
		$('.js-newsYears').append(dom);
	}

	// ローカルナビ
	if( $('#js-newsYears').length ){
		let dom = '';
		for( let i = 0; i < json.length; i++ ) {
			if( i === 0 ) {
				dom += `<li class="l-localNavi__col l-localNavi__title"><a href="/news/">${json[i]}年度</a></li>`;
			} else {
				dom += `<li class="l-localNavi__col l-localNavi__title"><a href="/news/${json[i]}/">${json[i]}年度</a></li>`;
			}
		}
		$('#js-newsYears').append(dom);
	}

	// セレクトメニュー
	if( $('.js-newsSelect').length ){
		let dom = '';
		for( let i = 0; i < json.length; i++ ) {
			if( i === 0 ) {
				dom += `<option value="/news/">${json[i]}年度</option>`;
			} else {
				dom += `<option value="/news/${json[i]}/">${json[i]}年度</option>`;
			}
		}
		$('.js-newsSelect').append(dom);
	}

	// サイトマップ
	if( $('#js-newsSitemap').length ){
		let dom = '';
		for( let i = 0; i < json.length; i++ ) {
			if( i === 0 ) {
				dom += `<div class="c-grid__col c-grid__col__l__3 c-grid__col__m__6 c-grid__col__s__12">
					<ul class="c-list c-list--link">
						<li><a href="/news/">${json[i]}年度</a></li>
					</ul>
				</div>`;
			} else {
				dom += `<div class="c-grid__col c-grid__col__l__3 c-grid__col__m__6 c-grid__col__s__12">
					<ul class="c-list c-list--link">
						<li><a href="/news/${json[i]}/">${json[i]}年度</a></li>
					</ul>
				</div>`;
			}
		}
		$('#js-newsSitemap').append(dom);
	}
});

/**
 * 最新記事リスト取得
 */
let request_get_newList = $.ajax({
	type: 'GET',
	url: origin + '/news/top/1.0/732hUdjYZcF5Cq/get_newList/5',
	dataType: 'jsonp',
	jsonpCallback: 'get_newList',
});
request_get_newList.done(function(json) {
	if( $('#js-news').length ) {
		var dom = '';
		var data = json;

		for( let i in data ) {

			let tag = '';
			let blank = '_self';
			let thumb = data[i].thumb;
			let link = true;
			var date = moment( data[i].date ).format( 'YYYY年MM月DD日' );

			switch( data[i].genre ) {
			case 'グループ':
				tag = 'group';
				break;
			case 'メディア':
				tag = 'media';
				break;
			case 'IRニュース':
				tag = 'ir';
				break;
			case '決算資料':
				tag = 'earnings';
				break;
			default:
				break;
			}

			switch( data[i].type ) {
			case 'タイトルのみ':
				break;
			case '内部リンク':
				url = data[i].URL;
				break;
			case '内部リンク別窓':
				url = data[i].URL;
				blank = '_blank';
				break;
			case '外部リンク':
				url = data[i].URL;
				break;
			case '外部リンク別窓':
				url = data[i].URL;
				blank = '_blank';
				break;
			case '詳細pdfあり':
				url = '/news/' + data[i].nendo + '/' + data[i].article_id;
				break;
			case '詳細pdfなし':
				url = '/news/' + data[i].nendo + '/' + data[i].article_id;
				break;
			case 'pdfのみ':
				url = data[i].URL;
				blank = '_blank';
				break;
			default:
				break;
			}

			if( data[i].type === 'タイトルのみ' ) {
				link = false;
			}

			if( link ) {
				dom += `<li class="news-item">
					<a class="news-item__link" href="${url}" target="${blank}">
						<div class="news-item__content">
							<div class="news-item__head">
								<p class="news-item__date">${date}</p>
								<p class="news-item__cat"><span class="news-item__cat--${tag}">${data[i].genre}</span></p>
							</div>
							<div class="news-item__thumb"><img src="${origin + thumb}" alt=""></div>
							<p class="news-item__title">${data[i].title}</p>
						</div>
					</a>
				</li>`;
			} else {
				dom += `<li class="news-item">
					<div class="news-item__inner">
						<div class="news-item__content">
							<div class="news-item__head">
								<p class="news-item__date">${date}</p>
								<p class="news-item__cat"><span class="news-item__cat--${tag}">${data[i].genre}</span></p>
							</div>
							<div class="news-item__thumb"><img src="${origin + humb}" alt=""></div>
							<p class="news-item__title">${data[i].title}</p>
						</div>
					</div>
				</li>`;
			}
		}
		$('#js-news').append(dom);
	}
});



/**
 * ページ生成
 * - /news/：最新年度一覧
 * - /news/YYYY/：年度一覧
 * - /news/YYYY/yyyymmddnn：詳細記事
 */
let url = location.pathname;
let url_array = url.split('/');
let url_length = url_array.length;

let re_top = '/news/';
let re_archive = /^\/news\/[0-9]{4}\/$/i;
let re_detail = /^\/news\/[0-9]{4}\/[0-9]{10}$/i;

if( url === re_top ) {
	let current_year = JSON.parse(localStorage.getItem( 'years' ));
	current_year = current_year[0];

	let request = $.ajax({
		type: 'GET',
		url: origin + '/news/list/1.0/732hUdjYZcF5Cq/create_archivePage/' + current_year + '/',
		dataType: 'jsonp',
		jsonpCallback: 'create_archivePage',
	});

	request.done(function(json) {
		if( $('#js-newsPage').length ) {
			var dom = '';
			var data;
			var year;
			var years = JSON.parse(localStorage.getItem( 'years' ));

			for( let key in json ){
				data = json[key];
				year = key;
			}

			// DOM 追加
			dom += `<div class="c-container c-container--wrap">
				<h1 class="c-titleA c-titleA--delete-mb"><span>${year}年度 ニュースリリース</span></h1>
			</div>
			<div class="c-container c-container--fixed">
				<div class="c-select c-select--layout-right c-select--add-mt c-select--add-mb">
					<div>
						<select class="js-select">
							<option value="">年を選択してください</option>

			`;
			for( let i = 0; i < years.length; i++ ){
				if( i === 0 ) {
					dom += `<option value="/news/">${years[i]}年度</option>`;
				} else {
					dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
				}
			}
			dom += `</select>
					</div>
				</div>
			</div>
			<ul class="p-card">
			`;
			for( let i = 0; i < data.length; i++ ){

				let tag = '';
				let blank = '_self';
				let thumb = data[i].thumb;
				let link = true;
				let url = '';
				let date = moment( data[i].date ).format( 'YYYY年MM月DD日' );

				switch( data[i].genre ) {
				case 'グループ':
					tag = 'group';
					break;
				case 'メディア':
					tag = 'media';
					break;
				case 'IRニュース':
					tag = 'ir';
					break;
				case '決算資料':
					tag = 'earnings';
					break;
				default:
					break;
				}

				switch( data[i].type ) {
				case 'タイトルのみ':
					break;
				case '内部リンク':
					url = data[i].URL;
					break;
				case '内部リンク別窓':
					url = data[i].URL;
					blank = '_blank';
					break;
				case '外部リンク':
					url = data[i].URL;
					break;
				case '外部リンク別窓':
					url = data[i].URL;
					blank = '_blank';
					break;
				case '詳細pdfあり':
					url = '/news/' + data[i].nendo + '/' + data[i].article_id;
					break;
				case '詳細pdfなし':
					url = '/news/' + data[i].nendo + '/' + data[i].article_id;
					break;
				case 'pdfのみ':
					url = data[i].URL;
					blank = '_blank';
					break;
				default:
					break;
				}


				if( data[i].type === 'タイトルのみ' ) {
					link = false;
				}

				if( link ) {
					dom += `<li class="p-card__col p-card__col__s__6 p-card__col__m__3 p-card__col__l__2">
						<a href="${url}" target="${blank}">
							<div class="p-card__thumb"><img src="${origin + thumb}" alt=""></div>
							<div class="p-card__content">
								<p class="p-card__date">${date}</p>
								<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
								<p class="p-card__title">${unescape( data[i].title )}</p>
							</div>
						</a>
					</li>`;
				} else {
					dom += `<li class="p-card__col p-card__col__s__6 p-card__col__m__3 p-card__col__l__2">
						<div>
							<div class="p-card__thumb"><img src="${origin + thumb}" alt=""></div>
							<div class="p-card__content">
								<p class="p-card__date">${date}</p>
								<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
								<p class="p-card__title">${unescape( data[i].title )}</p>
							</div>
						</div>
					</li>`;
				}
			}
			dom += `</ul>
			<div class="c-container c-container--fixed">
			<div class="p-links">
			<p class="c-rss"><a href="/rss.php">RSS</a></p>
			<div class="c-select">
				<div>
					<select class="js-select">
						<option value="">年を選択してください</option>
			`;
			for( let i = 0; i < years.length; i++ ){
				if( i === 0 ) {
					dom += `<option value="/news/">${years[i]}年度</option>`;
				} else {
					dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
				}
			}
			dom += `		</select>
						</div>
					</div>
				</div>
			</div>
			`;

			$('#js-newsPage').append(dom);

			var el = [].slice.call( document.querySelectorAll( '.js-select' ) );
			el.forEach( el => {
				el.addEventListener( 'change', () => {
					window.location.href = el.value;
				});
			} );
		}
	});

} else if( url.match( re_archive ) ) {
	let current_year = JSON.parse(localStorage.getItem( 'years' ))[0];
	let request = $.ajax({
		type: 'GET',
		url: origin + '/news/list/1.0/732hUdjYZcF5Cq/create_archivePage/' + url_array[url_length - 2] + '/',
		dataType: 'jsonp',
		jsonpCallback: 'create_archivePage',
	});

	request.done(function(json) {
		if( $('#js-newsPage').length ) {
			var dom = '';
			var data;
			var year;
			var years = JSON.parse(localStorage.getItem( 'years' ));

			for( let key in json ){
				data = json[key];
				year = key;
			}

			// 2018年以降
			if( year >= 2018 ) {

				// DOM 追加
				dom += `<div class="c-container c-container--wrap">
					<h1 class="c-titleA c-titleA--delete-mb"><span>${year}年度 ニュースリリース</span></h1>
				</div>
				<div class="c-container c-container--fixed">
					<div class="c-select c-select--layout-right c-select--add-mt c-select--add-mb">
						<div>
							<select class="js-select">
								<option value="">年を選択してください</option>

				`;
				for( let i = 0; i < years.length; i++ ){
					if( i === 0 ) {
						dom += `<option value="/news/">${years[i]}年度</option>`;
					} else {
						dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
					}
				}
				dom += `</select>
						</div>
					</div>
				</div>
				<ul class="p-card">
				`;
				for( let i = 0; i < data.length; i++ ){

					let tag = '';
					let blank = '_self';
					let thumb = data[i].thumb;
					let link = true;
					let url = '';
					let date = moment( data[i].date ).format( 'YYYY年MM月DD日' );

					switch( data[i].genre ) {
					case 'グループ':
						tag = 'group';
						break;
					case 'メディア':
						tag = 'media';
						break;
					case 'IRニュース':
						tag = 'ir';
						break;
					case '決算資料':
						tag = 'earnings';
						break;
					default:
						break;
					}

					switch( data[i].type ) {
					case 'タイトルのみ':
						break;
					case '内部リンク':
						url = data[i].URL;
						break;
					case '内部リンク別窓':
						url = data[i].URL;
						blank = '_blank';
						break;
					case '外部リンク':
						url = data[i].URL;
						break;
					case '外部リンク別窓':
						url = data[i].URL;
						blank = '_blank';
						break;
					case '詳細pdfあり':
						url = '/news/' + data[i].nendo + '/' + data[i].article_id;
						break;
					case '詳細pdfなし':
						url = '/news/' + data[i].nendo + '/' + data[i].article_id;
						break;
					case 'pdfのみ':
						url = data[i].URL;
						blank = '_blank';
						break;
					default:
						break;
					}


					if( data[i].type === 'タイトルのみ' ) {
						link = false;
					}

					if( link ) {
						dom += `<li class="p-card__col p-card__col__s__6 p-card__col__m__3 p-card__col__l__2">
							<a href="${url}" target="${blank}">
								<div class="p-card__thumb"><img src="${origin + thumb}" alt=""></div>
								<div class="p-card__content">
									<p class="p-card__date">${date}</p>
									<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
									<p class="p-card__title">${data[i].title}</p>
								</div>
							</a>
						</li>`;
					} else {
						dom += `<li class="p-card__col p-card__col__s__6 p-card__col__m__3 p-card__col__l__2">
							<div>
								<div class="p-card__thumb"><img src="${origin + thumb}" alt=""></div>
								<div class="p-card__content">
									<p class="p-card__date">${date}</p>
									<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
									<p class="p-card__title">${data[i].title}</p>
								</div>
							</div>
						</li>`;
					}
				}
				dom += `</ul>
				<div class="c-container c-container--fixed">
				<div class="p-links">
				<p class="c-rss"><a href="/rss.php">RSS</a></p>
				<div class="c-select">
					<div>
						<select class="js-select">
							<option value="">年を選択してください</option>
				`;
				for( let i = 0; i < years.length; i++ ){
					if( i === 0 ) {
						dom += `<option value="/news/">${years[i]}年度</option>`;
					} else {
						dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
					}
				}
				dom += `		</select>
							</div>
						</div>
					</div>
				</div>
				`;

			} else {

				// DOM 追加
				dom += `<div class="c-container c-container--wrap">
					<h1 class="c-titleA c-titleA--delete-mb"><span>${year}年度 ニュースリリース</span></h1>
					<div class="c-select c-select--layout-right c-select--add-mt c-select--add-mb">
						<div>
							<select class="js-select">
								<option value="">年を選択してください</option>

				`;
				for( let i = 0; i < years.length; i++ ){
					if( i === 0 ) {
						dom += `<option value="/news/">${years[i]}年度</option>`;
					} else {
						dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
					}
				}
				dom += `	</select>
						</div>
					</div>
					<div class="p-news">
						<ul class="p-news__items">
				`;
				for( let i = 0; i < data.length; i++ ){
					let tag = '';
					let blank = '_self';
					let link = true;
					let url = '';
					let date = moment( data[i].date ).format( 'YYYY年MM月DD日' );

					switch( data[i].genre ) {
					case 'グループ':
						tag = 'group';
						break;
					case 'メディア':
						tag = 'media';
						break;
					case 'IRニュース':
						tag = 'ir';
						break;
					case '決算資料':
						tag = 'earnings';
						break;
					default:
						break;
					}

					switch( data[i].type ) {
					case 'タイトルのみ':
						break;
					case '内部リンク':
						url = data[i].URL;
						break;
					case '内部リンク別窓':
						url = data[i].URL;
						blank = '_blank';
						break;
					case '外部リンク':
						url = data[i].URL;
						break;
					case '外部リンク別窓':
						url = data[i].URL;
						blank = '_blank';
						break;
					case '詳細pdfあり':
						url = '/news/' + data[i].nendo + '/' + data[i].article_id;
						break;
					case '詳細pdfなし':
						url = '/news/' + data[i].nendo + '/' + data[i].article_id;
						break;
					case 'pdfのみ':
						url = data[i].URL;
						blank = '_blank';
						break;
					default:
						break;
					}


					if( data[i].type === 'タイトルのみ' ) {
						link = false;
					}

					if( link ) {
						dom += `<li>
							<a href="${url}" target="${blank}">
								<p class="p-news__date">${date}</p>
								<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
								<p class="p-news__text p-news__text--${blank}"><span>${data[i].title}</span></p>
							</a>
						</li>`;
					} else {
						dom += `<li>
							<span>
								<p class="p-news__date">${date}</p>
								<p class="c-tag"><span class="c-tag__item c-tag__item--${tag}">${data[i].genre}</span></p>
								<p class="p-news__text p-news__text--${blank}"><span>${data[i].title}</span></p>
							</span>
						</li>`;
					}
				}
				dom += `</ul>
				<div class="c-container c-container--fixed">
				<div class="p-links">
				<p class="c-rss"><a href="/rss.php">RSS</a></p>
				<div class="c-select">
					<div>
						<select class="js-select">
							<option value="">年を選択してください</option>
				`;
				for( let i = 0; i < years.length; i++ ){
					if( i === 0 ) {
						dom += `<option value="/news/">${years[i]}年度</option>`;
					} else {
						dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
					}
				}
				dom += `		</select>
							</div>
						</div>
					</div>
				</div>
				`;
			}

			$('#js-newsPage').append(dom);

			var el = [].slice.call( document.querySelectorAll( '.js-select' ) );
			el.forEach( el => {
				el.addEventListener( 'change', () => {
					window.location.href = el.value;
				});
			} );
		}
	});
} else if( url.match(re_detail) ){
	let request = $.ajax({
		type: 'GET',
		url: origin + '/news/article/1.0/732hUdjYZcF5Cq/create_detailPage/' + url_array[url_length - 1] + '/',
		dataType: 'jsonp',
		jsonpCallback: 'create_detailPage',
	});

	request.done(function(json) {
		if( $('#js-newsPage').length ) {
			let dom = '';
			let data = json[0];
			let tag = '';
			let date = moment( data.article_id.substr( 0, 8 ) ).format( 'YYYY年MM月DD日' );
			let years = JSON.parse(localStorage.getItem( 'years' ));
			let size = '';
			let detail = true;
			let title
				= data.title
					.replace('<br />', '')
					.replace('<br/>', '')
					.replace('</br>', '')
					.replace('<br>', '');

			let breadCrumb = `<li><a href="/">Home</a></li>
			<li><a href="/">ニュースリリース</a></li>
			<li>${title}</li>`;
			$('.l-breadCrumb').html( breadCrumb );

			switch( data.genre ) {
			case 'グループ':
				tag = 'group';
				break;
			case 'メディア':
				tag = 'media';
				break;
			case 'IRニュース':
				tag = 'ir';
				break;
			case '決算資料':
				tag = 'earnings';
				break;
			default:
				break;
			}

			switch( data.type ) {
			case 'タイトルのみ':
			case '内部リンク':
			case '内部リンク別窓':
			case '外部リンク':
			case '外部リンク別窓':
			case 'pdfのみ':
				detail = false;
				break;
			}

			// DOM 追加
			dom += `<div class="c-container c-container--wrap p-article">
				<div class="p-articleTitle">
					<div class="p-articleTitle__sub">
						<ul class="c-tag"><li class="c-tag__item c-tag__item--${tag}">${data.genre}</li></ul>
						<p class="p-articleTitle__date">${date}</p>
					</div>
					<h1 class="p-articleTitle__main"><span>${data.title}</span></h1>
				</div>
				<div class="c-container c-container--inner">
					<div class="p-articleMeta">
						<img src="${origin + data.thumb}" alt="" class="p-articleMeta__image">
						<p class="p-articleMeta__text">${date}<br>
						株式会社IMAGICA GROUP</p>
					</div>
			`;
			if( detail ) {
				dom += data.html;
				if( data.pdf && 0 <= data.pdf.indexOf('.pdf') ) {
					size = String( Math.round( data.size / 1024 ) ).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

					dom += `<div class="c-download c-download--pdf">
						<a href="${origin + data.pdf}" target="_blank">
							<div class="c-download__wrap">
								<p class="c-download__inner">詳細PDFはこちら<span>[${size}KB]</span></p>
							</div>
						</a>
					</div>`;
				}
				if( data.email ) {
					dom += `<div class="p-contact">
						<p class="p-contact__title">本件に関するお問い合わせ先</p>
						<p>${data.section}<br><span class="p-contact__ib">E-Mail:<a href="mailto:${data.email}">${data.email}</a></span></p>
					</div>`;
				}
			}
			dom += '<div class="p-pagination">';
			if( data.prev ) {
				dom += `<p class="p-pagination__prev c-link"><a href="/news/${data.nendo}/${data.prev}">前へ</a></p>`;
			}
			if( data.next ) {
				dom += `<p class="p-pagination__next c-link c-link--reverse"><a href="/news/${data.nendo}/${data.next}">次へ</a></p>`;
			}
			dom += `<div class="p-pagination__utility">
				<p class="p-pagination__top c-link"><a href="/news/">ニューストップへ</a></p>
					<div class="p-pagination__select c-select">
					<div>
						<select class="js-select">
							<option value="">年を選択してください</option>`;
			for( let i = 0; i < years.length; i++ ){
				if( i === 0 ) {
					dom += `<option value="/news/">${years[i]}年度</option>`;
				} else {
					dom += `<option value="/news/${years[i]}/">${years[i]}年度</option>`;
				}
			}
			dom += `		</select>
						</div>
					</div>
				</div>
			</div>`;

			$('#js-newsPage').append(dom);

			var el = [].slice.call( document.querySelectorAll( '.js-select' ) );
			el.forEach( el => {
				el.addEventListener( 'change', () => {
					window.location.href = el.value;
				});
			} );
		}
	});
}

$(document).ready(function () {
  if (window.location.hash) {
    var targetId = window.location.hash;
    var target = $(targetId);

    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top - $('#l-globalNavi').height()
      }, 0);
    }
  }
});