'use strict';

export default class FooterPagetop {
	constructor( trigger ) {
		this.trigger = trigger;

		this.status = {
			windowHeight: 0,
			scroll: 0,
			bodyHeight: 0,
			currentPos: 0,
			footerHeight: 0
		};

		this.bodyEl = document.querySelector( 'body' );
		this.footerEl = document.querySelector( '#l-footer' );
	}

	setStatus() {
		this.status.windowHeight =  window.innerHeight;
		this.status.scroll = window.pageYOffset;
		this.status.bodyHeight = this.bodyEl.offsetHeight;
		this.status.currentPos = this.status.windowHeight + this.status.scroll;
		this.status.footerHeight = this.footerEl.offsetHeight;

		if( this.status.bodyHeight - this.status.currentPos <= this.status.footerHeight ){
			this.trigger.classList.remove( 'is-fixed' );
		}else{
			this.trigger.classList.add( 'is-fixed' );
		}

	}
}
