'use strict';
export default class SelectYear {
	constructor( conf ) {
		this.el = [].slice.call( document.querySelectorAll( conf.selector ) );
	}

	init() {
		this.el.forEach( el => {
			el.addEventListener( 'change', () => {
				window.location.href = el.value;
			});
		} );
	}
}
