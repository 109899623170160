'use strict';

export default class Presen {
	constructor( selector ) {
		this.index = [].slice.call( document.querySelectorAll( selector.index ) );
		this.pages = [].slice.call( document.querySelectorAll( selector.pages ) );
		this.prev = document.querySelector( selector.prev );
		this.next = document.querySelector( selector.next );
	}

	init() {
		this.index.forEach( ( el ) => {
			el.addEventListener( 'click', () => {
				let num = el.dataset.index;
				let crnt = document.querySelector( '[data-page].is-active' );
				let next = document.querySelector( '[data-page = "' + num +  '" ]' );

				crnt.classList.remove( 'is-active' );
				next.classList.add( 'is-active' );

				if( next.classList.contains('is-first') ) {
					this.prev.style.display = 'none';
					this.next.style.display = 'block';
				} else if( next.classList.contains('is-last') ) {
					this.prev.style.display = 'block';
					this.next.style.display = 'none';
				} else {
					this.prev.style.display = 'block';
					this.next.style.display = 'block';
				}
			});
		});

		this.next.addEventListener( 'click', () => {
			let crnt = document.querySelector( '[data-page].is-active' );
			let next = crnt.nextElementSibling;
			crnt.classList.remove( 'is-active' );
			next.classList.add( 'is-active' );

			if( next.classList.contains('is-first') ) {
				this.prev.style.display = 'none';
				this.next.style.display = 'block';
			} else if( next.classList.contains('is-last') ) {
				this.prev.style.display = 'block';
				this.next.style.display = 'none';
			} else {
				this.prev.style.display = 'block';
				this.next.style.display = 'block';
			}
		});

		this.prev.addEventListener( 'click', () => {
			let crnt = document.querySelector( '[data-page].is-active' );
			let prev = crnt.previousElementSibling;
			crnt.classList.remove( 'is-active' );
			prev.classList.add( 'is-active' );

			if( prev.classList.contains('is-first') ) {
				this.prev.style.display = 'none';
				this.next.style.display = 'block';
			} else if( prev.classList.contains('is-last') ) {
				this.prev.style.display = 'block';
				this.next.style.display = 'none';
			} else {
				this.prev.style.display = 'block';
				this.next.style.display = 'block';
			}
		});
	}
}
